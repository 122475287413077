import React, { Fragment, useState, useEffect } from "react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

import {
  SectionContainer,
  SectionInnerWrapper,
} from "../../../components/Layout/styled"

import Footer from "../../../components/Layout/Footer"
import { withUserContext } from "../../../context/UserContext"
import SeoComp from "../../../components/SeoComp"

import { isBrowser } from "../../../context/ApolloContext"
import { isLoadedScript } from "../../../utils/additionalScriptHelpers"

const Parrainage = ({ intl, data, userContext }) => {
  const {
    profile: { firstName, lastName, email },
    profile,
  } = userContext

  const [fName, setfName] = useState("")
  const [lName, setlName] = useState("")
  const [Email, setEmail] = useState("")

  const setAllStatesInitalValue = () => {
    setfName(firstName ? firstName : "")
    setlName(lastName ? lastName : "")
    setEmail(email ? email : "")
  }

  const addScript = () => {
    if (isBrowser) {
      let srcUrl =
        document.location.protocol == "https:"
          ? "//d11yp7khhhspcr.cloudfront.net"
          : "//cdn.invitereferrals.com" + "/js/invite-referrals-1.0.js"
      if (!isLoadedScript(srcUrl)) {
        var invite_referrals = window.invite_referrals || {}
        ;(function() {
          invite_referrals.auth = {
            bid_e: "09909CDEB2869813B6584F62B556528C",
            bid: "62536",
            t: "420",
            email: Email,
            userParams: { fname: fName },
            showWidget: "",
          }
          var script = document.createElement("script")
          script.async = true
          script.src = srcUrl
          document.body.appendChild(script)
          // var entry = document.getElementsByTagName("script")[0]
          // entry.parentNode.insertBefore(script, entry)
        })()
      }
    }
  }

  useEffect(() => {
    setAllStatesInitalValue()
  }, [profile])

  useEffect(() => {
    // addScript()
  }, [])

  return (
    data &&
    data.allDirectusUserDashboardTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          hreflangPathname={{
            fr: "mon-compte/parrainage",
            en: "",
            de: "",
            es: "",
          }}
          noindex={true}
          nofollow={true}
        />
        <SectionContainer>
          <SectionInnerWrapper>
            {process.env &&
            process.env.GATSBY_ACTIVE_ENV === "eur" &&
            intl.locale === "fr" ? (
              <RowWrapper>
                {Email && fName && <MentionWrapper id="mmWrapper" />}
              </RowWrapper>
            ) : (
              <RowWrapper>
                <ColumnWrapper>
                  {Email && fName && (
                    <Iframe
                      align="middle"
                      frameBorder={0}
                      src={
                        "https://www.ref-r.com/campaign_user/p?brandid=62536&campaignid=30861&bid_e=09909CDEB2869813B6584F62B556528C&t=420&email=" +
                        Email +
                        "&fname=" +
                        fName
                      }
                    />
                  )}
                </ColumnWrapper>
                <ColumnWrapper>
                  <Image
                    className="lazyload"
                    data-src="https://static.percko.com/uploads/b3820025-4697-4183-a07e-a96b5e364c3b.jpg"
                  />
                </ColumnWrapper>
              </RowWrapper>
            )}
          </SectionInnerWrapper>
        </SectionContainer>

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

const RowWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  max-width: 1080px;
  width: 100%;
`

const ColumnWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  width: 100%;
  color: #262626;
  margin: 0px;
`

const Iframe = styled.iframe`
  max-width: 100%;
  width: 100%;
  height: 570px;
  margin: 0px 10px 0px 0px;

  @media (max-width: 767px) {
    margin: 0px;
  }
`

const Image = styled.img`
  object-fit: cover;
  margin: 0px 0px 0px 10px;
  width: 100%;

  @media (max-width: 767px) {
    display: none;
  }
`

const MentionWrapper = styled.div`
  width: 100%;

  iframe {
    width: 100%;
  }
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusUserDashboardTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        first_name_string
        last_name_string
        account_section_info
        email_address_string
        account_display_name_string
        account_password_change_string
        account_current_password_string
        account_confirm_password_string
        account_new_password_string
        account_save_changes_button
        language
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "parrainage_dashboard" } }
      }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default withUserContext(injectIntl(Parrainage))
